"use client"

import { SvgSearch } from "@store-platform/ui/icons"
import { Button } from "../Button/Button"
import { useIsMobile, useListingSearch } from "../../hooks"

export const SearchButton = () => {
  const isMobile = useIsMobile()
  const { ref } = useListingSearch()
  return (
    <Button
      variant="naked"
      className="flex justify-start items-center pl-2 text-gray-600 rounded-lg bg-gray-100 min-w-max gap-x-2 max-sm:mx-4 py-2 sm:py-4"
      href={isMobile ? "/search" : undefined}
      onClick={!isMobile ? () => ref.current?.focus() : undefined}
      asLink={isMobile}
    >
      <SvgSearch className="h-5 w-5 text-blue-500" />
      Search for an app...
    </Button>
  )
}
